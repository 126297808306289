import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
    TETabsContent,
    TETabsPane,
} from "tw-elements-react";

// Custom components
import AuditAddBags from '../AuditAddBags';
import AuditOrderActions from '../AuditOrderActions';
import AuditQueue from '../AuditQueue';
import AuditVoucher from '../AuditVoucher';

// Custom styles
import '../styles/auditLanding.scss';
import '../styles/auditGlobal.scss';
import AuditMismatch from '../AuditMismatch';

const AuditLanding = () => {
    // State
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [tabValue, setTabValue] = useState("tab1");
    
    // Functions
    const tabHandler = (value) => {
        if (value === tabValue) {
            return;
          }
          setTabValue(value);
    }

    return (
        <div className="manualInvoiceLanding mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
            {!isLoggedIn && (
                <Navigate to="/login" />
            )}

            <h3 className='text-2xl font-semibold text-center'>Auditing</h3>

            <div className="mb-3">
                <div className="auditLanding__tabButtonWrapper">
                    <div className={tabValue === "tab1" ? "auditLanding__tabButton active" : "auditLanding__tabButton"}>
                        <button
                            onClick={() => tabHandler("tab1")}
                        >
                            Order Actions Audit
                        </button>
                    </div>

                    <div className={tabValue === "tab2" ? "auditLanding__tabButton active" : "auditLanding__tabButton"}>
                        <button
                            onClick={() => tabHandler("tab2")}
                        >
                            Bags Audit
                        </button>
                    </div>

                    <div className={tabValue === "tab3" ? "auditLanding__tabButton active" : "auditLanding__tabButton"}>
                        <button
                            onClick={() => tabHandler("tab3")}
                        >
                            Queue Fixes
                        </button>
                    </div>

                    <div className={tabValue === "tab4" ? "auditLanding__tabButton active" : "auditLanding__tabButton"}>
                        <button
                            onClick={() => tabHandler("tab4")}
                        >
                            Promo/Voucher Code Audits
                        </button>
                    </div>

                    <div className={tabValue === "tab5" ? "auditLanding__tabButton active" : "auditLanding__tabButton"}>
                        <button
                            onClick={() => tabHandler("tab5")}
                        >
                            Card Mismatch Audits
                        </button>
                    </div>
                </div>

                <TETabsContent>
                    <TETabsPane show={tabValue === "tab1"}>
                        <AuditOrderActions />
                    </TETabsPane>

                    <TETabsPane show={tabValue === "tab2"}>
                        <AuditAddBags />
                    </TETabsPane>

                    <TETabsPane show={tabValue === "tab3"}>
                        <AuditQueue />
                    </TETabsPane>

                    <TETabsPane show={tabValue === "tab4"}>
                        <AuditVoucher />
                    </TETabsPane>

                    <TETabsPane show={tabValue === "tab5"}>
                        <AuditMismatch />
                    </TETabsPane>
                </TETabsContent>
            </div>
        </div>
    )
};

export default AuditLanding;