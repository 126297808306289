import { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

// Custom components
import Loader from '../../Loader/Loader';

// Env
const { REACT_APP_SUPPORT_API } = process.env;

const InvoicePaymentErrorPrevious = () => {
    // State
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [data, setData] = useState([]);

    // Methods
    const renderInvoices = () => {
        const invoices = data.map((invoice) => (
            <tr key={invoice.uid}>
                <td>
                    {invoice.uid}
                </td>
                <td>
                    {invoice.created_datetime}
                </td>
                <td>
                    {invoice.edi_invoice_status}
                </td>
                <td>
                    {invoice.edi_message_response}
                </td>
                <td>
                    {invoice.edi_order_no}
                </td>
            </tr>
        ))

        return invoices;
    }

    const getData = () => {
        setIsLoading(true);
        axios.get(`${REACT_APP_SUPPORT_API}invoices/card_mismatch_previous`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => {
            setData(response.data.orders);
            setIsLoading(false);
        },
        (error) => {
            if (error.code === 'ERR_NETWORK') {
                setIsLoggedIn(false);
            }
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getData()
    }, [setData]);

    return (
        <div>
            {!isLoggedIn && (
                <Navigate to="/login" />
            )}

            {isLoading ? (
                <Loader />
            ) : (
                <table className="invoicePaymentError__table">
                    <thead>
                        <tr>
                            <th>Invoice ID</th>
                            <th>Created At</th>
                            <th>EDI Invoice Status</th>
                            <th>EDI Response</th>
                            <th>EDI Order No</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderInvoices()}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default InvoicePaymentErrorPrevious;