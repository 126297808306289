import React, { useState } from 'react';
import axios from 'axios';
import {
    TERipple,
    TEModal,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
    TEModalFooter,
} from 'tw-elements-react';

// Custom styles
import './markAsDelivered.scss';

const { REACT_APP_SUPPORT_API } = process.env;

const MarkAsDelivered = (props) => {
    // Props
    const {
        orderId,
        toastHandler,
        reloadOrder
    } = props;

    // State
    const [isDeliveredModalOpen, setIsDeliveredModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Functions
    const toggleModal = () => {
        setIsDeliveredModalOpen(!isDeliveredModalOpen);
    }

    const submitMarkOrderAsDelivered = () => {
        setIsLoading(true);
        
        axios({
            method: 'post',
            url: `${REACT_APP_SUPPORT_API}manualOrder/markAsDelivered`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            data: {
                'uid': orderId,
                'user_id': localStorage.getItem('userId'),
                'token': localStorage.getItem('token')
            }
        })
        .then((response) => {
            setIsDeliveredModalOpen(false);
            setIsLoading(false);
            reloadOrder({orderId});
            toastHandler(true, 'Order has been marked as delivered');
        },
        (error) => {
            setIsDeliveredModalOpen(false);
            setIsLoading(false);
            if (error.response.data) {
                const message = error.response.data.message;
                toastHandler(true, message);
            } else {
                toastHandler(true, 'There was a problem trying to update the order');
            }
        });
    }

    return (
        <div>
            <TERipple rippleColor="white">
                <button
                    type="button"
                    className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] button__primary"
                    onClick={toggleModal}
                >
                    Mark Order as Delivered
                </button>
            </TERipple>

            <TEModal
                show={isDeliveredModalOpen}
                setShow={toggleModal}
            >
                <TEModalDialog>
                    <TEModalContent
                        className='cancelOrder__modal'
                    >
                        <TEModalHeader>
                            {/* <!--Modal title--> */}
                            <h5 className="text-xl font-medium leading-normal">
                                Are you sure you want to mark order {orderId} as Delivered?
                            </h5>
                            {/* <!--Close button--> */}
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setIsDeliveredModalOpen(!isDeliveredModalOpen)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                                </svg>
                            </button>
                        </TEModalHeader>

                        <TEModalFooter>
                            <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 button__secondary"
                                    onClick={() => setIsDeliveredModalOpen(!isDeliveredModalOpen)}
                                >
                                    Cancel
                                </button>
                            </TERipple>

                            <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] button__primary markAsDelivered__button"
                                    onClick={submitMarkOrderAsDelivered}
                                >
                                    {isLoading ? (
                                        <span
                                            className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-6px] motion-reduce:animate-[spin_1.5s_linear_infinite] markAsDelivered__spinner"
                                            role="status"
                                        >
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >
                                                Loading...
                                            </span>
                                        </span>
                                    ) : (
                                        <span>Mark order as Delivered</span>
                                    )}
                                </button>
                            </TERipple>
                        </TEModalFooter>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    )
}

export default MarkAsDelivered;