import { Routes, Route } from "react-router-dom";

// Global styles
import './css/main.css';
import './styles/global.scss';

// Custom pages
import Login from './pages/login/Login';
import DefaultLayout from "./layouts/DefaultLayout/DefaultLayout";
import AuditLanding from "./components/audit/AuditLanding/AuditLanding";
import ManualInvoiceLanding from './components/manual/ManualInvoiceLanding/ManualInvoiceLanding';
import General from "./pages/general/General";
import Invoice from "./pages/invoice/Invoice";

function App() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="" element={<DefaultLayout />}>
                <Route index element={<ManualInvoiceLanding />} />
                <Route path="audit" element={<AuditLanding />} />
                <Route path="general" element={<General />} />
                <Route path="invoice" element={<Invoice />} />
            </Route>
        </Routes>
    )
}

export default App;
