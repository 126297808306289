import './loader.scss';

const Loader = () => {
    return (
        <div className="loader__wrapper">
            <div>
                <div className="loader__innerWrapper">
                    <img src="/pick-n-pay-logo.svg" alt="pnp logo" />
                </div>
            </div>
        </div>
    )
}

export default Loader;
