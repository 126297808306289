import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import {
    TEAlert,
    TERipple
} from 'tw-elements-react';

// Custom components
import AddBags from '../AddBags/AddBags';
import CancelOrder from '../CancelOrder/CancelOrder';
import MarkAsDelivered from '../MarkAsDelivered/MarkAsDelivered';
import Toast from '../../toast/Toast';

// Custom styles
import './manualInvoiceLanding.scss';

const { REACT_APP_SUPPORT_API } = process.env;

const ManualInvoiceLanding = () => {
    // State
    const [isSearchSuccess, setIsSearchSuccess] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [orderData, setOrderData] = useState({});
    const [bagData, setBagData] = useState(null);
    const [manualIsLoggedIn, setManualIsLoggedIn] = useState(true);
    const [orderId, setOrderId] = useState("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    // Functions
    const submitOrderId = (e) => {
        e.preventDefault();
        getOrder();
    }

    const searchHandler = (e) => {
        setOrderId(e.target.value)
        setIsSearchSuccess(false);
        setNotFound(false);
    }

    const getOrder = () => {
        setIsSearchLoading(true);
        axios.get(`${REACT_APP_SUPPORT_API}manualOrder/${orderId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => {
            setIsSearchLoading(false);
            setIsSearchSuccess(true);
            setNotFound(false);
            const data = response.data.order_details;

            const order = {
                'order_id': data.uid,
                'status' : data.status,
                'store_code': data.store_uid,
                'item_totals': `R${data.items_total}`,
                'order_total': `R${data.order_total}`,
                'invoice_no': data.invoice_no,
                'invoice_status': data.invoice_status,
                'created_datetime': moment(data.created_datetime).format('D MMM YYYY'),
                'last_status_changed_datetime': moment(data.last_status_changed_datetime).format('D MMM YYYY'),
                'placed_datetime': moment(data.placed_datetime).format('D MMM YYYY'),
            };
            setOrderData(order);
            if (response.data.bag_details !== null) {
                setBagData(response.data.bag_details.quantity);
            } else {
                setBagData(null);
            }
        },
        (error) => {
            setIsSearchLoading(false);
            setIsSearchSuccess(false);
            // setIsLoading(false);
            if (error.response) {
                if (error.code === 'ERR_NETWORK') {
                    setManualIsLoggedIn(false);
                }
                if (error.response.status === 404) {
                    setNotFound(true);
                } else {
                    // setError('There was a problem connecting to the server. Please try again later');    
                }
            } else {
                // setError('There was a problem connecting to the server. Please try again later');
            }
        });
    }

    const toastHandler = (isOpen, message) => {
        setIsToastOpen(isOpen);
        setToastMessage(message);
    }

    return (
        <div className="manualInvoiceLanding mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
            {!manualIsLoggedIn && (
                <Navigate to="/login" />
            )}
            <h3 className='text-2xl font-semibold text-center'>Manual Order Fixes</h3>
            
            <form
                onSubmit={(e) => submitOrderId(e)}
            >
                <div className="manualInvoiceLanding__searchForm">
                    <div className="manualInvoiceLanding__searchInput">
                        <input
                            type="text"
                            className="w-full p-2 pe-12 text-sm shadow-sm"
                            placeholder="Search for Order ID"
                            value={orderId}
                            onChange={(e) => searchHandler(e)}
                            // disabled={isLoading}
                        />

                        <div className="manualInvoiceLanding__searchSubmitWrapper">
                            <TERipple rippleColor="white">
                                <button
                                    type="submit"
                                    className="text-white font-bold px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 button__primary"
                                >
                                    {isSearchLoading ? (
                                        <span
                                            className="inline-block h-6 w-6 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-6px] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                            role="status"
                                        >
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >
                                                Loading...
                                            </span>
                                        </span>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                        </svg>
                                    )}
                                </button>
                            </TERipple>
                        </div>
                    </div>
                   
                </div>
            </form>

            {isSearchSuccess && (
                <>
                    <table className="manualInvoiceLanding__table">
                        <thead>
                            <tr>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Order Id</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Status</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Store UID</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Items Total</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Order Total</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Invoice ID</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Invoice Status</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Number of Bags</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Created At</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Last Status Update</th>
                                <th className="text-sm manualInvoiceLanding__tableHeader">Order Placed date</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.order_id}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.status}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.store_code}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.item_totals}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.order_total}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.invoice_no}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.invoice_status}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{bagData === null ? 'No bags' : bagData}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.created_datetime}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.last_status_changed_datetime}</td>
                                <td className="text-sm manualInvoiceLanding__tableContent">{orderData.placed_datetime}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="manualInvoiceLanding__actionButtonWrapper">
                        <CancelOrder
                            orderId={orderData.order_id}
                            reloadOrder={getOrder}
                            toastHandler={toastHandler}
                        />

                        <MarkAsDelivered
                            orderId={orderData.order_id}
                            reloadOrder={getOrder}
                            toastHandler={toastHandler}
                        />

                        <AddBags
                            orderId={orderData.order_id}
                            reloadOrder={getOrder}
                            toastHandler={toastHandler}
                        />
                    </div>

                    <Toast
                        isOpen={isToastOpen}
                        message={toastMessage}
                        toastHandler={toastHandler}
                    />
                </>
            )}

            {notFound && (
                <>
                    <TEAlert staticAlert open={true} color="bg-danger-100 text-danger-700">
                        <span className="mr-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-5 w-5"
                            >
                                <path
                                fillRule="evenodd"
                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                clipRule="evenodd"
                                />
                            </svg>
                        </span>
                        Order {orderId} was not found
                    </TEAlert>
                </>
            )}
        </div>
    )
}

export default ManualInvoiceLanding;