import { useState } from 'react';

// Custom Components
import DeletePromoCode from '../../components/GeneralFixes/DeletePromoCode/DeletePromoCode';
import EdiQueueFix from '../../components/GeneralFixes/EdiQueueFix/EdiQueueFix';
import PnFix from "../../components/GeneralFixes/PnFix/PnFix";
import Toast from '../../components/toast/Toast';

// Custom styles
import './general.scss';

const General = () => {
    // State
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const toastHandler = (isOpen, message) => {
        setIsToastOpen(isOpen);
        setToastMessage(message);
    }

    return (
        <div className="general mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <h3 className='text-2xl font-semibold text-center'>General Fixes</h3>

            <div className="general__actionButtonWrapper">
                <PnFix
                    toastHandler={toastHandler}
                />

                <DeletePromoCode
                    toastHandler={toastHandler}
                />

                <EdiQueueFix
                    toastHandler={toastHandler}
                />
            </div>

            <Toast
                isOpen={isToastOpen}
                message={toastMessage}
                toastHandler={toastHandler}
            />
        </div>
    )
}

export default General;