import { TEToast } from "tw-elements-react";

const Toast = (props) => {
    const {
        isOpen,
        toastHandler,
        message,
    } = props;

    return (
        <div>
            <TEToast
                autohide={true}
                delay={3000}
                open={isOpen}
                color="bg-primary-100 text-primary-700"
                className="mb-6"
                onClosed={() => toastHandler(false, '')}
            >
                <div className="flex items-center justify-between rounded-t-lg border-b-2 border-primary-200 bg-clip-padding px-4 pb-2 pt-2.5">
                    <div className="break-words rounded-b-lg px-4 py-4">
                        {message}
                    </div>
                </div>
            </TEToast>
        </div>
    )
}

export default Toast;