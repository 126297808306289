import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const { REACT_APP_SUPPORT_API } = process.env;

const AuditQueue = () => {
    // State
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    // Functions
    const renderData = () => {
        const tableData = data.map((item) => (
            <tr>
                <td>{item.user_id}</td>
                <td>{item.queue_fixed}</td>
                <td>{item.humanDate}</td>
            </tr>
        ))

        return tableData;   
    }

    // Effect to get all auto fix audits.
    useEffect(() => {
        const getData = () => {
            setIsLoading(true);
            axios.get(`${REACT_APP_SUPPORT_API}audit/queue`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data.audit_data;
                    setData(data);

                    if (data.length > 0) {
                        // Customise date to make it readable.
                        for (let x = 0; x < data.length; x++) {
                            data[x].key = x;
                            data[x].humanDate = moment(data[x].updated_at).format('D MMM YYYY')
                        }
                    }
                    
                    setIsLoading(false);
                } else {
                    setData([]);
                    setIsLoading(false);    
                }
            },
            (error) => {
                if (error.code === 'ERR_NETWORK') {
                    setIsLoggedIn(false);
                }
                setData([]);
                setIsLoading(false);
            })
        }
        
        getData()
    }, [setData]);

    return (
        <div>
            {!isLoggedIn && (
                <Navigate to="/login" />
            )}

            <table className="audit__table">
                <thead>
                    <tr>
                        <th>Support User ID</th>
                        <th>Queue fixed</th>
                        <th>Updated At</th>
                    </tr>
                </thead>

                <tbody>
                    {renderData()}
                </tbody>
            </table>
        </div>
    )
};

export default AuditQueue;