import { NavLink } from 'react-router-dom';
import './menu.scss';

const Menu = (props) => {
    const { isMenuOpen, permissions, logoutHandler, triggerHandler } = props;

    return (
        <div className='menu'>
            <div
                className="menu__triggerWrapper p-2"
                onClick={triggerHandler}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </div>

            <div className="menu__logo">
                <img src="/pick-n-pay-logo.svg" alt="pnp logo" />
            </div>

            <div className={isMenuOpen ? "menu__content active" : "menu__content"} >
                {permissions !== null && (
                    <>
                        <div className="menu__imgWrapper">
                            <img src="/pick-n-pay-logo.svg" alt="pnp logo" />
                        </div>
                        <ul>
                            <li
                                className="menu__item"
                                onClick={triggerHandler}
                            >
                                <NavLink
                                    to="/"
                                >
                                    <span>Single Order Fixes</span>
                                </NavLink>
                            </li>

                            <li
                                className="menu__item"
                                onClick={triggerHandler}
                            >
                                <NavLink
                                    to="/general"
                                >
                                    <span>General Fixes</span>
                                </NavLink>
                            </li>

                            <li
                                className="menu__item"
                                onClick={triggerHandler}
                            >
                                <NavLink
                                    to="/invoice"
                                >
                                    <span>Invoice Fixes</span>
                                </NavLink>
                            </li>

                            <li
                                className="menu__item"
                                onClick={triggerHandler}
                            >
                                <NavLink
                                    to="/audit"
                                >
                                    <span>Audit</span>
                                </NavLink>
                            </li>

                            <li
                                className="menu__item"
                                onClick={logoutHandler}
                            >
                                <NavLink
                                    to="/login"
                                >
                                    <span>Log out</span>
                                </NavLink>
                            </li>
                        </ul>
                    </>
                )}

                <div
                    className="menu__closeWrapper p-2"
                    onClick={triggerHandler}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Menu;
