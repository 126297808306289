import { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { TERipple } from "tw-elements-react";

// Custom components
import InvoicePaymentErrorPrevious from "./InvoicePaymentErrorPrevious";
import Loader from '../../Loader/Loader';
import Toast from '../../toast/Toast';

// Custom styles
import './invoicePaymentError.scss';

// Env
const { REACT_APP_SUPPORT_API } = process.env;

const InvoicePaymentError = () => {
    // State
    const [invoiceIdArr, setInvoiceIdArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [data, setData] = useState([]);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showPrevious, setShowPrevious] = useState(false);
    const [feedback, setFeedback] = useState([]);

    // Methods
    const invoiceIdHandler = (id, discrepancy) => {
        const newInvoiceArr = [...invoiceIdArr];
        const index = newInvoiceArr.findIndex(e => e.id === id);

        const invoiceDetailObj = {
            "id": id,
			"discrepancy": discrepancy 
        }

        if (index > -1) {
            newInvoiceArr.splice(index, 1);
        } else {
            // Only add if there are less than 10 selected.
            if (invoiceIdArr.length < 10) {
                newInvoiceArr.push(invoiceDetailObj);
            }
        }
        setInvoiceIdArr(newInvoiceArr);
    }

    const toggleFixed = () => {
        setShowPrevious(!showPrevious);
    }

    const toggleFeedbackHandler = () => {
        setFeedback([]);
    }

    const applyFixHandler = () => {
        setIsLoading(true);
        setFeedback([]);

        axios({
            method: 'post',
            url: `${REACT_APP_SUPPORT_API}invoices/card_mismatch`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            data: {
                'user_id': localStorage.getItem('userId'),
                'invoice_details': invoiceIdArr
            }
        })
        .then((response) => {
            setIsLoading(false);
            toastHandler(true, "Card mismatch fix was applied");
            setFeedback(response.data.details);
            setInvoiceIdArr([]);
        },
        (error) => {
            if (error.code === 'ERR_NETWORK') {
                setIsLoggedIn(false);
            }
            setIsLoading(false);
            toastHandler(true, "Card mismatch fix failed to run");
        })
    }

    const getData = () => {
        setIsLoading(true);
        axios.get(`${REACT_APP_SUPPORT_API}invoices`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => {
            setData(response.data.invoices);
            setIsLoading(false);
        },
        (error) => {
            if (error.code === 'ERR_NETWORK') {
                setIsLoggedIn(false);
            }
            setIsLoading(false);
        })
    }

    const toastHandler = (isOpen, message) => {
        setIsToastOpen(isOpen);
        setToastMessage(message);
    }

    const renderFeedback = () => {
        const details = feedback.map((detail) => (
            <tr key={detail.id}>
                <td>{detail.id}</td>
                <td>{detail.detail}</td>
                <td>{detail.seq_no}</td>
                <td>{detail.message}</td>
            </tr>
        ))

        return details;
    }

    const renderInvoices = () => {
        const invoices = data.map((invoice) => (
            <tr key={invoice.uid}>
                <td>
                    {invoice.uid}
                </td>
                <td>
                    {invoice.created_datetime}
                </td>
                <td>
                    {invoice.edi_invoice_status}
                </td>
                <td>
                    {invoice.edi_message_response}
                </td>
                <td>
                    {invoice.edi_order_no}
                </td>
                <td>
                    {invoice.discrepancy}
                </td>
                <td>
                    <span
                        onClick={() => invoiceIdHandler(invoice.uid, invoice.discrepancy)}
                        className={invoiceIdArr.some(e => e.id === invoice.uid) ? "invoicePaymentError__toggle active" : "invoicePaymentError__toggle"}   
                    ></span>
                </td>
            </tr>
        ))

        return invoices;
    }

    useEffect(() => {
        getData()
    }, [setData]);

    return (
        <div>
            {!isLoggedIn && (
                <Navigate to="/login" />
            )}

            <div className="invoicePaymentError__actionWrapper">
                <TERipple rippleColor="light">
                    <button
                        type="button"
                        className="inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                        onClick={getData}
                    >
                        Reload Data
                    </button>
                </TERipple>

                <TERipple rippleColor="light">
                    <button
                        type="button"
                        className="inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                        onClick={toggleFixed}
                    >
                        {showPrevious ? "View mismatch invoices" : "View fixed invoices"}
                    </button>
                </TERipple>

                <TERipple rippleColor="light">
                    <button
                        type="button"
                        className="inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                        onClick={applyFixHandler}
                        disabled={invoiceIdArr.length < 1}
                    >
                        Apply Fix
                    </button>
                </TERipple>
            </div>
            
            {feedback.length > 0 && (
                <div className="invoicePaymentError__feedback">
                    <span
                        className="invoicePaymentError__feedbackClose"
                        onClick={toggleFeedbackHandler}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#fff"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                            />
                        </svg>
                    </span>

                    <table className="invoicePaymentError__table">
                            <thead>
                                <tr>
                                    <th>Invoice ID</th>
                                    <th>Detail</th>
                                    <th>Seq NO</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderFeedback()}
                            </tbody>
                        </table>
                </div>
            )} 
            
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {showPrevious ? (
                        <InvoicePaymentErrorPrevious />
                    ) : (
                        <table className="invoicePaymentError__table">
                            <thead>
                                <tr>
                                    <th>Invoice ID</th>
                                    <th>Created At</th>
                                    <th>EDI Invoice Status</th>
                                    <th>EDI Response</th>
                                    <th>EDI Order No</th>
                                    <th>Discrepancy</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderInvoices()}
                            </tbody>
                        </table>
                    )} 
                </>
            )}

            <Toast
                isOpen={isToastOpen}
                message={toastMessage}
                toastHandler={toastHandler}
            />
        </div>
    )
}

export default InvoicePaymentError;