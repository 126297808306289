import { useState } from 'react';
import { TERipple } from "tw-elements-react";

// Custom Components
import InvoicePaymentError from '../../components/invoice/InvoicePaymentError/InvoicePaymentError';

// Custom styles
import './invoice.scss';

const Invoice = () => {
    return (
        <div className="manualInvoiceLanding mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <h3 className='text-2xl font-semibold text-center'>Invoice Fixes</h3>

            <InvoicePaymentError />
        </div>
    )
}

export default Invoice;