import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import {
    TEModal,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TERipple,
} from 'tw-elements-react';

// Custom styles
import './deletePromoCode.scss';

const { REACT_APP_SUPPORT_API } = process.env;

const DeletePromoCode = (props) => {
    // Props
    const {
        toastHandler,
    } = props;

    // State
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    // Functions
    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const submitDeletePromoCode = (e) => {
        e.preventDefault();
        deletePromoCodeHandler();
    }

    const deletePromoCodeHandler = () => {
        setIsLoading(true);
        axios({
            method: 'post',
            url: `${REACT_APP_SUPPORT_API}general/deletePromoCode`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            data: {
                'promo_code': promoCode,
                'user_id': localStorage.getItem('userId'),
            }
        })
        .then((response) => {
            setIsOpen(false);
            setIsLoading(false);
            toastHandler(true, 'Promo/Voucher Code deleted successfully');
        },
        (error) => {
            setIsOpen(false);
            setIsLoading(false);
            if (error.code === 'ERR_NETWORK') {
                setIsLoggedIn(false);
            }

            if (error.response.data) {
                const message = error.response.data.message;
                toastHandler(true, message);
            } else {
                toastHandler(true, 'There was a problem trying to delete the Promo/Voucher Code');
            }
        });
    }

    return (
        <div>
            {!isLoggedIn && (
                <Navigate to="/login" />
            )}
            <TERipple rippleColor="white">
                <button
                    type="button"
                    className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] button__primary"
                    onClick={toggleModal}
                >
                    Delete Promo or Voucher Codes
                </button>
            </TERipple>

            <TEModal
                show={isOpen}
                setShow={toggleModal}
            >
                <TEModalDialog>
                    <TEModalContent
                        className='cancelOrder__modal'
                    >
                        <TEModalHeader>
                            {/* <!--Modal title--> */}
                            <h5 className="text-xl font-medium leading-normal">
                                Delete promo/voucher code
                            </h5>
                            {/* <!--Close button--> */}
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setIsOpen(!isOpen)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                                </svg>
                            </button>
                        </TEModalHeader>

                        <TEModalBody>
                            <form
                                onSubmit={(e) => submitDeletePromoCode(e)}
                            >
                                <input
                                    type="text"
                                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                    placeholder="Enter Promo/Voucher code"
                                    value={promoCode}
                                    onChange={(e) => setPromoCode(e.target.value)}
                                    disabled={isLoading}
                                />
                            </form>
                        </TEModalBody>

                        <TEModalFooter>
                            <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 button__secondary"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    Cancel
                                </button>
                            </TERipple>

                            <TERipple rippleColor="light">
                                <button
                                    type="button"
                                    className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] button__primary deletePromoCode__button"
                                    onClick={deletePromoCodeHandler}
                                    disabled={promoCode.length < 1}
                                >
                                    {isLoading ? (
                                        <span
                                            className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-6px] motion-reduce:animate-[spin_1.5s_linear_infinite] deletePromoCode__spinner"
                                            role="status"
                                        >
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >
                                                Loading...
                                            </span>
                                        </span>
                                    ) : (
                                        <span>Delete Promo/Voucher Code</span>
                                    )}
                                </button>
                            </TERipple>
                        </TEModalFooter>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    )
}

export default DeletePromoCode;