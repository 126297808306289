import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, Link } from 'react-router-dom';
import axios from 'axios';

// Custom components
import Menu from '../../components/Menu/Menu';

const { REACT_APP_SUPPORT_API } = process.env;

const DefaultLayout = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const triggerHandler = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const logoutHandler = () => {
        setIsLoggedIn(false);
        setIsMenuOpen(!isMenuOpen);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
    }

    return (
        <div>
            {!isLoggedIn && (
                <Navigate to="/login" />
            )} 
            <>
                <div
                    className={isMenuOpen ? "defaultLayout_overlay active" : "defaultLayout_overlay"}
                    onClick={triggerHandler}
                />
                <Menu 
                    triggerHandler={triggerHandler}
                    isMenuOpen={isMenuOpen}
                    logoutHandler={logoutHandler}
                />
                <div className="defaultLayout__content">
                    <Outlet />
                </div>
            </>
        </div>
    );
  };
  
  export default DefaultLayout;
